<template>
  <b-row>
    <b-col sm="12" md="12" class="mt-1">
      <b-form @submit.prevent>
        <b-card>
          <b-card-body>
            <validation-observer
              #default="{ handleSubmit }"
              ref="refFormObserver"
            >
              <b-form
                @submit.prevent="handleSubmit(onSubmit)"
                @reset.prevent="resetForm"
              >
                <b-row>
                  <b-col sm="12" md="6" class="mt-1">
                    <label> {{ $t("fields.accountholdername") }}</label>

                    <inputvselect
                      name="accountholdername"
                      :label="(reldata) => reldata.name"
                      :valuex="appData.holdernameid"
                      keys="key"
                      @updatedata="(val) => updatedetails('holdernameid', val)"
                      :options="familymembers"
                      id="holdername"
                      validations="required"
                    ></inputvselect>
                  </b-col>
                  <b-col sm="12" md="6" class="mt-1">
                    <label> {{ $t("fields.typeofloan") }}</label>
                    <inputselect
                      name="typeofloan"
                      :tooltip="
                        $t('fields.select') + ' ' + $t('fields.typeofloan')
                      "
                      :placeholder="
                        $t('fields.select') + ' ' + $t('fields.typeofloan')
                      "
                      :valuex="appData.category"
                      keys="key"
                      @updatedata="(val) => (appData.category = val)"
                      :options="category"
                      id="category"
                      validations="required"
                    ></inputselect>
                  </b-col>
                  <b-col
                    sm="12"
                    md="6"
                    class="mt-1"
                    v-if="
                      ['Agriculture Loan', 'Land Loan'].includes(
                        appData.category
                      )
                    "
                  >
                    <inputtext
                      name="propertyid"
                      :tooltip="
                        $t('fields.enter') + ' ' + $t('fields.propertyid')
                      "
                      :label="$t('fields.propertyid')"
                      :placeholder="
                        $t('fields.enter') + ' ' + $t('fields.propertyid')
                      "
                      :valuex="appData.propertyid"
                      @updatedata="(val) => (appData.propertyid = val)"
                      validations=""
                    ></inputtext>
                  </b-col>
                  <b-col
                    sm="12"
                    md="6"
                    class="mt-1"
                    v-if="['Auto Loan'].includes(appData.category)"
                  >
                    <inputtext
                      name="registrationnumber"
                      :tooltip="
                        $t('fields.enter') +
                        ' ' +
                        $t('fields.registrationnumber')
                      "
                      :label="$t('fields.registrationnumber')"
                      :placeholder="
                        $t('fields.enter') +
                        ' ' +
                        $t('fields.registrationnumber')
                      "
                      :valuex="appData.regnno"
                      @updatedata="(val) => (appData.regnno = val)"
                      validations="required|min:8|max:10"
                    ></inputtext>
                  </b-col>
                  <b-col sm="12" md="6" class="mt-1">
                    <inputtext
                      name="lender"
                      :tooltip="$t('fields.enter') + ' ' + $t('fields.lender')"
                      :label="$t('fields.lender')"
                      :placeholder="
                        $t('fields.enter') + ' ' + $t('fields.lender')
                      "
                      :valuex="appData.loanavailedfrom"
                      @updatedata="(val) => (appData.loanavailedfrom = val)"
                      validations="required"
                    ></inputtext>
                  </b-col>
                  <b-col sm="12" md="6" class="mt-1">
                    <inputtext
                      name="tenure"
                      :tooltip="$t('fields.enter') + ' ' + $t('fields.tenure')"
                      :label="$t('fields.tenure')"
                      :placeholder="
                        $t('fields.enter') + ' ' + $t('fields.tenure')
                      "
                      :valuex="appData.tenure"
                      @updatedata="
                        (val) => {
                          appData.tenure = val;
                          emicalculate();
                        }
                      "
                      validations=""
                    ></inputtext>
                  </b-col>
                  <b-col sm="12" md="6" class="mt-1">
                    <inputtext
                      name="loanid"
                      :tooltip="$t('fields.enter') + ' ' + $t('fields.loanid')"
                      :label="$t('fields.loanid')"
                      :placeholder="
                        $t('fields.enter') + ' ' + $t('fields.loanid')
                      "
                      :valuex="appData.loanid"
                      @updatedata="(val) => (appData.loanid = val)"
                      validations=""
                    ></inputtext>
                  </b-col>
                  <b-col sm="12" md="6" class="mt-1">
                    <inputtext
                      name="sanctionedamount"
                      :tooltip="
                        $t('fields.enter') + ' ' + $t('fields.sanctionedamount')
                      "
                      :label="$t('fields.sanctionedamount')"
                      :placeholder="
                        $t('fields.enter') + ' ' + $t('fields.sanctionedamount')
                      "
                      :valuex="appData.sanctionedamount"
                      @updatedata="
                        (val) => {
                          appData.sanctionedamount = val;
                          emicalculate();
                        }
                      "
                      validations="required|integer|amount"
                    ></inputtext>
                  </b-col>
                  <b-col sm="12" md="6" class="mt-1">
                    <inputtext
                      name="rateofintrest"
                      :tooltip="
                        $t('fields.enter') + ' ' + $t('fields.rateofintrest')
                      "
                      :label="$t('fields.rateofintrest')"
                      :placeholder="
                        $t('fields.enter') + ' ' + $t('fields.rateofintrest')
                      "
                      :valuex="appData.rateofintrest"
                      @updatedata="
                        (val) => {
                          appData.rateofintrest = val;
                          emicalculate();
                        }
                      "
                      validations="positive|minvalue:0.1|maxvalue:100"
                    ></inputtext>
                  </b-col>
                  <b-col sm="12" md="6" class="mt-1">
                    <label> {{ $t("fields.startdate") }}</label>
                    <flat-pickr
                      v-model="appData.startdate"
                      :config="{
                        enableTime: false,
                        dateFormat: 'd-m-Y',
                        maxDate: today,
                        onChange:emicalculate()
                      }"
                      locale="en-In"
                      placeholder="DD-MM-YYYY"
                      class="form-control"
                    />
                  </b-col>

                  <b-col sm="12" md="6" class="mt-2">
                    <inputtext
                      name="emi"
                      :tooltip="$t('fields.enter') + ' ' + $t('fields.emi')"
                      :label="$t('fields.emi')"
                      :disabled=1
                      :placeholder="$t('fields.enter') + ' ' + $t('fields.emi')"
                      :valuex="appData.emi || 0"
                      @updatedata="(val) => (appData.emi = val)"
                      validations="required|amount"
                    ></inputtext>
                  </b-col>
                  <b-col sm="12" md="6" class="mt-2">
                    <b-row>
                      <b-col class="col-5">
                        <inputtext
                          name="date"
                          :label="$t('fields.emiduedate')"
                          :tooltip="
                            $t('fields.enter') + ' ' + $t('fields.pleaseinput')
                          "
                          placeholder="DD"
                          :valuex="appData.emidate"
                          @updatedata="(val) => (appData.emidate = val)"
                          validations="required|min:1|maxvalue:31"
                        ></inputtext>
                      </b-col>
                      <b-col class="col-7">
                        <label class="mt-1 pt-1">
                          {{ $t("fields.ofeverymonth") }}</label
                        >
                      </b-col>
                    </b-row>
                  </b-col>
                  <b-col sm="12" md="6" class="mt-1">
                    <inputtext
                      name="prinipalamount"
                      :tooltip="
                        $t('fields.enter') + ' ' + $t('fields.prinipalamount')
                      "
                      :label="$t('fields.prinipalamount')"
                      :placeholder="
                        $t('fields.enter') + ' ' + $t('fields.prinipalamount')
                      "
                      :valuex="appData.prinipalamount"
                      @updatedata="
                        (val) => managefloat('prinipalamount', parseFloat(val))
                      "
                      validations="required|integer|amount"
                    ></inputtext>
                  </b-col>
                  <b-col sm="12" md="6" class="mt-1">
                    <label> {{ $t("fields.dateofclosure") }}</label>
                    <flat-pickr
                      v-model="appData.dateofclosure"
                      :config="{
                        enableTime: false,
                        dateFormat: 'd-m-Y',
                        minDate: today,
                      }"
                      locale="en-In"
                      placeholder="DD-MM-YYYY"
                      class="form-control"
                    />
                  </b-col>

                  <b-col sm="12" md="6" class="mt-1">
                    <inputtext
                      name="remarks"
                      :tooltip="$t('fields.enter') + ' ' + $t('fields.remarks')"
                      :label="$t('fields.remarks')"
                      :placeholder="
                        $t('fields.enter') + ' ' + $t('fields.remarks')
                      "
                      :valuex="appData.remarks"
                      @updatedata="(val) => (appData.remarks = val)"
                      validations=""
                    ></inputtext>
                  </b-col>
                  <b-col v-if="edittype == 0" sm="12" md="6" class="mt-1">
                    <label> {{ $t("fields.dateofentry") }}</label>
                    <p>{{ appData.date }}</p>
                  </b-col>
                  <b-col sm="12" v-if="edittype == 0">
                    <h5 class="mt-2">{{ $t("fields.fileupload") }}</h5>
                    <b-row>
                      <b-col sm="12" md="6" class="mt-1" lg="6">
                        <validation-provider
                          #default="validationContext"
                          name="Upload File"
                          rules=""
                        >
                          <b-form-group
                            :label="$t('fields.fileinput')"
                            label-for="fileinput"
                          >
                            <b-form-file
                              accept="image/*,application/pdf,.doc,.docx,.xls,.xlsx,.csv,.tsv,.ppt,.pptx,.pages,.odt,.rtf"
                              id="fileinput"
                              name="Upload File"
                              placeholder="Upload File "
                              drop-placeholder="Drop file here..."
                              @change="updateValue($event)"
                            />
                            <b-form-invalid-feedback>
                              {{ validationContext.errors[0] }}
                            </b-form-invalid-feedback>
                          </b-form-group>
                        </validation-provider>
                      </b-col>
                    </b-row>
                  </b-col>

                  <b-col sm="12">
                    <div class="d-flex float-right">
                      <b-button
                        v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                        class="mr-1"
                        variant="secondary"
                        @click="$router.go(-1)"
                      >
                        <feather-icon icon="ArrowLeftIcon" class="mr-25" />
                        <span class="align-middle"
                          >{{ $t("fields.back") }}
                        </span>
                      </b-button>
                      <b-button
                        v-if="edittype == 1"
                        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                        variant="primary"
                        class="mr-1"
                        :to="{
                          name: GENAPP_APP_STORE_MODULE_NAME + '-view',
                          params: { id: iddata },
                        }"
                      >
                        <feather-icon icon="EyeIcon" class="mr-25" />
                        {{ $t("fields.view") }}
                      </b-button>
                      <b-button
                        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                        variant="primary                            "
                        class="mr-1"
                        type="submit"
                      >
                        <feather-icon icon="Edit3Icon" class="mr-25" />
                        {{ $t("fields.save") }}
                      </b-button>
                    </div></b-col
                  >
                </b-row>
              </b-form>
              <!--/ form -->
            </validation-observer>
          </b-card-body>
        </b-card>
      </b-form>
    </b-col>
  </b-row>
</template>

  <script>
import {
  BRow,
  BCol,
  BCard,
  BCardBody,
  BFormDatepicker,
  BForm,
  BFormGroup,
  BFormInput,
  BFormCheckbox,
  BFormFile,
  BFormInvalidFeedback,
  BButton,
} from "bootstrap-vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import inputvselect from "@/views/Component/Input/inputvselect.vue";
import inputselect from "@/views/Component/Input/inputselect.vue";
import inputtext from "@/views/Component/Input/inputtext.vue";
import inputtextarea from "@/views/Component/Input/inputtextarea.vue";
import { required, alphaNum, maxvalue } from "@validations";
import flatPickr from "vue-flatpickr-component";
import { ref, onUnmounted } from "@vue/composition-api";
import router from "@/router";
import store from "@/store";
import formValidation from "@core/comp-functions/forms/form-validation";
import Ripple from "vue-ripple-directive";
import StoreModule from "./StoreModule";
import { useToast } from "vue-toastification/composition";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import vSelect from "vue-select";
export default {
  components: {
    BCol,
    BCard,
    BRow,
    BForm,
    flatPickr,
    BCardBody,
    BFormGroup,
    inputselect,
    inputvselect,
    inputtextarea,
    inputtext,
    BFormCheckbox,
    BFormInput,
    BFormFile,
    BFormInvalidFeedback,
    BButton,
    ToastificationContent,
    ValidationProvider,
    ValidationObserver,
    BFormDatepicker,
    vSelect,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      required,
      alphaNum,
      maxvalue,
    };
  },
  setup(props, { emit }) {
    const GENAPP_APP_STORE_MODULE_NAME = "liabilities-loan";
    const modulename = "Loan";
    const modulenamesub = "loan";
    // Register module
    if (!store.hasModule(GENAPP_APP_STORE_MODULE_NAME))
      store.registerModule(GENAPP_APP_STORE_MODULE_NAME, StoreModule);
    const toast = useToast();
    onUnmounted(() => {
      if (store.hasModule(GENAPP_APP_STORE_MODULE_NAME))
        store.unregisterModule(GENAPP_APP_STORE_MODULE_NAME);
    });
    let today = new Date();

    let key = 0;
    let edittype = 0;
    let storetype = "addData";
    let iddata = router.currentRoute.params.id;
    if (iddata != undefined) {
      edittype = 1;
      storetype = "editData";
    }
    const current = new Date();
    const date = `${current.getDate()}/${
      current.getMonth() + 1
    }/${current.getFullYear()}`;
    const blankAppData = {
      name: "",
      holdernameid: "",
      holdername: "",
      category: "",
      loanavailedfrom: "",
      loanid: "",
      regnno: "",
      prinipalamount: 0,
      emi: "",
      propertyid: "",
      startdate: "",
      emideddate: "",
      rateofintrest: "",
      sanctionedamount: "",
      registrationnumber: "",
      emidate: "",
      dateofclosure: "",
      dateofentry: "",
      tenure: "",
      remarks: "",
      date: date,
      fileData: {
        name: "",
        file: "",
        size: "",
        type: "",
      },
    };
    const appData = ref(JSON.parse(JSON.stringify(blankAppData)));
    var tempcatch = 1;

    const onSubmit = () => {
      let temp = appData.value;
      let formData = new FormData();

      let appdatax = {
        customvalue: temp.relation != "Others" ? 0 : 1,
        customrelation: temp.customrelation || "",
        holdername: temp.holdername,
        name: temp.name,
        holdernameid: temp.holdernameid,
        category: temp.category,
        loanavailedfrom: temp.loanavailedfrom,
        loanid: temp.loanid,
        dateofentry: temp.dateofentry,
        prinipalamount: temp.prinipalamount,
        emi: temp.emi,
        sanctionedamount: temp.sanctionedamount,
        regnno: temp.regnno,
        registrationnumber: temp.registrationnumber,
        propertyid: temp.propertyid,
        startdate: temp.startdate,
        date: temp.date,
        tenure: temp.tenure,
        rateofintrest: temp.rateofintrest,
        emidate: temp.emidate,
        dateofclosure: temp.dateofclosure,
        emideddate: temp.emideddate,
        remarks: temp.remarks,
      };
      let metadataappdatax = {
        customvalue: temp.relation != "Others" ? 0 : 1,
        customrelation: temp.customrelation || "",
        holdername: temp.holdername,
        name: temp.loanid,
        holdernameid: temp.holdernameid,
        category: temp.category,
        emidate: temp.emidate
          ? temp.emidate.split("-").reverse().join("-")
          : null,
        startdate: temp.startdate
          ? temp.startdate.split("-").reverse().join("-")
          : null,
        dateofclosure: temp.dateofclosure
          ? temp.dateofclosure.split("-").reverse().join("-")
          : null,
        dateofentry: temp.dateofentry
          ? temp.dateofentry.split("-").reverse().join("-")
          : null,
        emideddate: temp.emideddate
          ? temp.emideddate.split("-").reverse().join("-")
          : null,
        sanctionedamount: temp.sanctionedamount,
        propertyid: temp.propertyid,
        emi: temp.emi,
        prinipalamount: temp.prinipalamount,
      };

      if (edittype == 1) {
        var jx = {
          id: router.currentRoute.params.id || null,
          customvalue: metadataappdatax.customvalue,
          data: appdatax,
          name: temp.loanid,
          metadata: metadataappdatax,
        };
      } else {
        formData.append("name", temp.loanid);
        formData.append("customvalue", metadataappdatax.customvalue);
        formData.append("data", JSON.stringify(appdatax));
        formData.append("metadata", JSON.stringify(metadataappdatax));

        if (temp.fileData.file) {
          let file = temp.fileData.file;
          formData.append("file", file);
        }
      }
      if (tempcatch) {
        tempcatch = 0;
        store
          .dispatch(
            GENAPP_APP_STORE_MODULE_NAME + "/" + storetype,
            edittype == 0 ? formData : jx
          )
          .then((response) => {
            if (response.data.success) {
              toast({
                component: ToastificationContent,
                props: {
                  title: "Success",
                  text:
                    modulename +
                    (edittype == 1 ? " editted" : " added") +
                    " successfully",
                  icon: "CheckIcon",
                  variant: "success",
                },
              });
              router
                .push({
                  name: GENAPP_APP_STORE_MODULE_NAME,
                  params: {
                    id: response.data.id,
                  },
                })
                .catch(() => {});
            } else if (response.data.success == 0) {
              toast({
                component: ToastificationContent,
                props: {
                  title: "Error",
                  variant: "error",
                },
              });
              router
                .push({
                  name: GENAPP_APP_STORE_MODULE_NAME,
                  params: {
                    id: response.data.id,
                  },
                })
                .catch(() => {});
            }
          })
          .catch((error) => {
            tempcatch = 1;

            if (error.response.status === 404) {
              appData.value = undefined;
              toast({
                component: ToastificationContent,
                props: {
                  title: "Error",
                  text: "Couldnt find " + modulenamesub + " details",
                  icon: "AlertTriangleIcon",
                  variant: "danger",
                },
              });
            }
          });
      }
    };
    const familymembers = ref([]);
    store
      .dispatch(GENAPP_APP_STORE_MODULE_NAME + "/fetchefamilymemberdetails")
      .then((response) => {
        familymembers.value = response.data.data;
      });

    // const state = ref([]);
    // store
    //   .dispatch(GENAPP_APP_STORE_MODULE_NAME + "/fetchestate")
    //   .then((response) => {
    //     state.value = response.data.data;
    //   });
    if (iddata != undefined) {
      store
        .dispatch(GENAPP_APP_STORE_MODULE_NAME + "/fetchData", {
          id: router.currentRoute.params.id,
        })
        .then((response) => {
          if (!response.data.data) {
            toast({
              component: ToastificationContent,
              props: {
                title: "Error",
                text: "Couldnt find " + modulenamesub + " details",
                icon: "AlertTriangleIcon",
                variant: "danger",
              },
            });
            router
              .push({
                name: GENAPP_APP_STORE_MODULE_NAME,
              })
              .catch(() => {});
          }

          appData.value = response.data.data.data;
          appData.value.name = response.data.data.name;
          key = Math.random();
        })
        .catch((error) => {
          if (error.response.status === 404) {
            appData.value = undefined;
            toast({
              component: ToastificationContent,
              props: {
                title: "Error",
                text: "Couldnt find " + modulenamesub + " details",
                icon: "AlertTriangleIcon",
                variant: "danger",
              },
            });
          } else if (error.response.status === 500) {
            toast({
              component: ToastificationContent,
              props: {
                title: "Error",
                text: "Input fields missing values",
                icon: "AlertTriangleIcon",
                variant: "danger",
              },
            });
          } else if (error.response.status === 422) {
            toast({
              component: ToastificationContent,
              props: {
                title: "Error",
                text: "Please refresh your page",
                icon: "AlertTriangleIcon",
                variant: "danger",
              },
            });
          }
        });
    }
    const resetappData = () => {
      appData.value = JSON.parse(JSON.stringify(blankAppData));
    };
    const category = [
      "Agriculture Loan",
      "Auto Loan",
      "Business Loan",
      "Commercial Loan",
      "Consumer Loan",
      "Credit Loan",
      "Education Loan",
      "Friends/ Relative Loan",
      "Gold Loan",
      "Home Loan",
      "Overdraft Loan",
      "Land Loan",
      "P2P Lendring",
      "Personal Loan",
    ];

    const emidateopt = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15];
    const { refFormObserver, getValidationState, resetForm } =
      formValidation(resetappData);
    return {
      GENAPP_APP_STORE_MODULE_NAME,
      edittype,
      iddata,
      appData,
      modulename,
      modulenamesub,
      onSubmit,
      refFormObserver,
      getValidationState,
      resetForm,
      category,
      emidateopt,
      key,
      familymembers,
      today,
      tempcatch,
      // state
    };
  },
  data(){
    return {
        xdata:[],
    };
  },
  mounted() {},
  methods: {
    updatedetails(tit, val) {
      if (tit == "holdernameid") {
        this.appData.holdernameid = val.id;
        this.appData.holdername = val.name;
      }
    },
    managefloat(name, val) {
      this.$set(this.appData, name, parseFloat(val));
    },
    updateValue(value) {
      if (value.target.files[0].size <= 2 * 1048576) {
        this.appData.fileData.file = value.target.files[0];
        this.appData.fileData.name = value.target.files[0].name;
        this.appData.fileData.size = value.target.files[0].size;
        this.appData.fileData.type = value.target.files[0].type;
      } else {
        this.$swal({
          icon: "error",
          title: "File limit Reached!",
          text: "File size must be maximum 2 mb .",
          customClass: {
            confirmButton: "btn btn-success",
          },
        });
      }
    },
    currentDate() {
      const current = new Date();
      const date = `${current.getDate()}/${
        current.getMonth() + 1
      }/${current.getFullYear()}`;
      return date;
    },

    emicalculate() {
      var emi = 1;
      var p = this.appData.sanctionedamount;
      var r = this.appData.rateofintrest;
      var n = this.appData.tenure * 12;
      var r1 = (r / 12) / 100;
      var r2 = 1 + r1;
      var temp = Math.pow(1 + r1, n) / (Math.pow(1 + r1, n) - 1);
      emi = Math.ceil(p * r1 * temp);
      this.appData.emi = emi;
      if(this.appData.startdate){
        var tempx=this.appData.startdate
        tempx=tempx.split('-')
        var startdate = new Date(tempx[2], tempx[1], tempx[0])
        var enddate = new Date()
        let months;
        months = (enddate.getFullYear() - startdate.getFullYear()) * 12;
        months -= startdate.getMonth();
        months += enddate.getMonth();
        var pa=p
        var rx=0
        var px=0
        for(var i=0;i<months;i++){
            rx=(emi*r1)/100
            px=emi-rx
            pa=pa-px
            this.appData.prinipalamount=Math.round(pa*100)/100

            this.xdata.push(px,rx,pa)
        }
      }
    },
  },
};
</script>

  <style lang="scss">
@import "@core/scss/vue/libs/vue-flatpicker.scss";
</style>
